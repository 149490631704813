import {ModalManager} from './ModalManager'


const createManager = () => {
  const stack = document.getElementById('modal-stack')
  if (!stack) {
    throw new Error('Missing modal stack container.')
  }
  return new ModalManager(stack)
}

let managerInstance: ModalManager

export const getDialogManager = () => {
  if (!managerInstance) {
    managerInstance = createManager()
  }
  return managerInstance
}

export default () => {
  const manager = getDialogManager()
  document.querySelectorAll<HTMLElement>('[data-toggle="dialog"]').forEach(el => {
    el.addEventListener('click', async (event) => {
      const modalId = el.getAttribute('aria-controls')
      if (!modalId) return
      await manager.open(modalId)
    })
  })
}
