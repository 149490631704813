
export default () => {
  const bar = document.querySelector('.o-shopSidebar_actions')
  if (!bar) {
    return
  }
  const observer = new IntersectionObserver(records => {
    for (const entry of records) {
      const isSticking = !entry.isIntersecting
      bar.classList.toggle('-hasElevation', isSticking)
    }
  }, {
    rootMargin: '0px 0px -1px 0px',
    threshold: [1],
  })
  observer.observe(bar)
}
