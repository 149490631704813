<script lang="ts">
  import type {FetchResults, I18N, PostType, SearchResult} from './types'
  import EmptyState from './EmptyState.svelte'
  import ResultList from './ResultList.svelte'
  import SearchInput from './SearchInput.svelte'

  export let i18n: I18N
  export let minCharacters: number = 2
  export let fetchResults: FetchResults

  let searchTerm: string = ''
  let postType: PostType = 'product'
  let results: SearchResult[] = []
  let fetching = false

  $: termLength = searchTerm.length
  $: (async () => {
    if (termLength < minCharacters) {
      fetching = false
      results = []
      return
    }
    try {
      fetching = true
      results = await fetchResults(searchTerm, postType)
    } catch (err) {
      if (err.name !== 'AbortError') console.error(err, err.name)
      results = []
    } finally {
      fetching = false
    }
  })()
</script>

<div class="SearchForm">
  <SearchInput i18n={i18n} bind:searchTerm bind:postType showBusyIndicator={fetching} />
  {#if termLength < minCharacters }
    <EmptyState message={i18n.notEnoughCharacters.replace('%d', minCharacters.toString())} />
  {:else if results.length }
    <ResultList results={results} />
  {:else if fetching }
    <EmptyState message="..." />
  {:else}
    <EmptyState message={i18n.noResults} />
  {/if}
</div>

<style>
  .SearchForm {
    display: grid;
    grid-template-rows: auto 1fr;
    grid-row-gap: 1rem;
    height: 100%;
    overflow: hidden;
  }
</style>
