import {Classes, Selectors} from './selectors'
import {getDialogManager} from '../dialogs'
import {ModalManagerEvent, ModalManagerEvents} from '../dialogs/ModalManager'
import delegate from '../utils/delegate'

const DIALOG_ID = 'quick-view-dialog'

const fetchQuickView = async (url: string): Promise<string> => {
  const response = await fetch(url)
  if (!response.ok) {
    throw new Error(response.statusText)
  }
  return response.text()
}

const openDialog = async (html: string) => {
  const dialogManager = getDialogManager()

  dialogManager.once(ModalManagerEvents.OPEN, (event: ModalManagerEvent) => {
    const {dialog} = event.detail
    dialog.body.innerHTML = html
  })
  dialogManager.once(ModalManagerEvents.OPENED, async (event: ModalManagerEvent) => {
    const {dialog} = event.detail
    const {createImageGallery} = await import('./gallery')
    const gallery = await createImageGallery(dialog.body)
    dialogManager.once(ModalManagerEvents.CLOSED, () => {
      gallery.$destroy()
    })
  })
  await dialogManager.open(DIALOG_ID)
}

export default () => {
  delegate<HTMLElement>(document.body, 'click', Selectors.loadButton, async (event) => {
    event.preventDefault()
    const btn = event.delegateTarget
    const {quickViewUrl} = btn.dataset
    if (!quickViewUrl) {
      throw new Error('Missing quick-view URL.')
    }
    btn.classList.add(Classes.loading)
    try {
      const html = await fetchQuickView(quickViewUrl)
      await openDialog(html)
    } catch (err) {
      console.error(err)
    } finally {
      btn.classList.remove(Classes.loading)
    }
  })
}
