<script lang="ts">
  import {computeSize, US_SIZES, type UsSize} from './conversions'
  import type {Messages} from './i18n'

  export let size
  export let i18n: Messages

  let value: UsSize = {
    size: 'S',
    gender: 'female',
  }

  $: size = computeSize({unit: 'us', value})
</script>

<div role="group">
  <label>
    <span>{i18n.size}</span>
    <select bind:value={value.size}>
      {#each US_SIZES as size}
        <option value={size}>{size}</option>
      {/each}
    </select>
  </label>
  <label>
    <span class="u-hidden">{i18n.gender}</span>
    <select bind:value={value.gender}>
      <option value="male">{i18n.genders.male}</option>
      <option value="female">{i18n.genders.female}</option>
    </select>
  </label>
</div>
