
export const Easings = {
  standard: 'cubic-bezier(0.4, 0, 0.2, 1)',
  deceleration: 'cubic-bezier(0, 0, 0.2, 1)',
  acceleration: 'cubic-bezier(0.4, 0, 1, 1)',
  sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
}

export function animateTo(
  element: HTMLElement,
  to: Keyframe[] | PropertyIndexedKeyframes,
  options: KeyframeAnimationOptions = {},
) {
  const anim = element.animate(to, {...options, fill: 'both'})
  anim.addEventListener('finish', () => {
    try {
      // @ts-ignore
      anim.commitStyles()
    } catch (err) {
      // commitStyles() can throw if the element is not rendered, i.e. has been hidden w/ display:none
      // we can safely ignore that (or can we?)
    } finally {
      anim.cancel()
    }
  })
  return anim
}

export function animateFrom(
  element: HTMLElement,
  from: PropertyIndexedKeyframes,
  options: KeyframeAnimationOptions
) {
  return element.animate({...from, offset: 0}, {...options, fill: 'backwards'})
}
