import modulo from '../utils/modulo'
import {Tab} from './Tab'
import {CustomEventTarget} from '../utils/CustomEventTarget'

export type TabActivatedEvent = CustomEvent<{tabList: TabList, activeTab: Tab, previousTab: Tab|null}>
interface TabListEventMap {
  tabActivated: TabActivatedEvent,
}

const getTabNodes = (root: HTMLElement) => Array.from(
  root.querySelectorAll<HTMLElement>('[role=tab]')
)

export class TabList extends CustomEventTarget<TabListEventMap> {
  private readonly tabs: Tab[]
  private focusedTabIndex: number = 0

  constructor(
    private readonly root: HTMLElement
  ) {
    super()
    this.tabs = this.createTabs(root)
    this.root.addEventListener('keydown', this.handleKeyDown)
  }

  get activeTab(): Tab|null {
    return this.tabs.find(tab => tab.isActive) ?? null
  }

  async activateTabById(id: string) {
    const index = this.tabs.findIndex(tab => tab.controls === id)
    if (index > -1) {
      await this.activateTabAt(index)
    }
  }

  async activateTabAt(index: number) {
    const previousTab = this.activeTab
    const nextTab = this.getTabAt(index)
    if (previousTab !== nextTab) {
      if (previousTab) {
        previousTab.deactivate()
      }
      await nextTab.activate(previousTab)
      this.trigger('tabActivated', {tabList: this, activeTab: nextTab, previousTab})
    }
  }

  private createTabs(root: HTMLElement) {
    const nodes = getTabNodes(root)
    return nodes.map((node, i) => {
      const tab = new Tab(node, i === 0)
      tab.on('focused', () => this.focusedTabIndex = i)
      tab.on('activate', async (event) => {
        await this.activateTab(event.detail.tab)
      })
      return tab
    })
  }

  private async activateTab(tab: Tab) {
    const index = this.tabs.indexOf(tab)
    if (index > -1) {
      await this.activateTabAt(index)
    }
  }

  private getTabAt(index: number) {
    return this.tabs[modulo(index, this.tabs.length)]
  }

  private focusTabAt(index: number) {
    const tab = this.getTabAt(index)
    tab.focus()
  }

  private handleKeyDown = (event: KeyboardEvent) => {
    const {key} = event
    switch (key) {
      case 'ArrowLeft':
        event.preventDefault()
        this.focusTabAt(this.focusedTabIndex - 1)
        break;
      case 'ArrowRight':
        event.preventDefault()
        this.focusTabAt(this.focusedTabIndex + 1)
        break;
      case 'Home':
        event.preventDefault()
        this.focusTabAt(0)
        break;
      case 'End':
        event.preventDefault()
        this.focusTabAt(-1)
        break;
    }
  }
}
