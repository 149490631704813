import '../css/front.scss'

import initializeSentry from './all/sentry'
import initializeAnalytics from './front/analytics'
import initializeNavbar from './front/navbar'
import initializePrimaryNavigation from './front/menus/primary-navigation'
import initializeMobileNavigation from './front/menus/mobile-navigation'
import initializeDialogs from './front/dialogs'
import initializeTabs from './front/tabs'
import initializeSearchForm from './front/search'
import initializeColorSwatches from './front/color-swatches'
import initializeProductFilters from './front/product-filters'
import initializeProductGallery from './front/product-gallery'
import initializeProductQuickView from './front/quick-view'
// import initializeProductVariants from './front/product-variants'
import initializeVariationsForm from './front/variations-form'
import initializeLoopLayoutSelector from './front/products-loop/layout-selector'
import initializeProductsCarousels from './front/products-carousels'
import initializeSizeGuide from './front/size-guide'

document.addEventListener('DOMContentLoaded', () => {
  initializeSentry()
  initializeAnalytics()
  initializeNavbar()
  initializeMobileNavigation()
  initializePrimaryNavigation()
  initializeDialogs()
  initializeTabs()
  initializeSearchForm()
  initializeProductFilters()
  initializeVariationsForm()
  initializeColorSwatches()
  initializeProductGallery()
  initializeProductQuickView()
  // initializeProductVariants()
  initializeLoopLayoutSelector()
  initializeProductsCarousels(`
    .o-wcSingleProduct_related .o-wcLoop,
    .cross-sells .o-wcLoop
  `)
  initializeSizeGuide()
})
