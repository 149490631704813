<script lang="ts">
  import {fly} from 'svelte/transition'
  import {SearchResult} from './types'

  const transitionIn = {y: 100, duration: 250}
  const transitionOut = {y: 100, duration: 150}

  export let results: SearchResult[] = []
</script>

<div class="SearchResultList">
  {#each results as result (result.url)}
    <div class="SearchResultList_result" in:fly={transitionIn} out:fly={transitionOut}>
      {#if result.image}
        <img
          src={result.image.src}
          width={result.image.width} height={result.image.height}
          alt={result.title}
        >
      {/if}
      <a href={result.url}>{result.title}</a>
    </div>
  {/each}
</div>

<style>
  .SearchResultList {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow-y: auto;
  }
  .SearchResultList_result {
    border: 1px solid grey;
    padding: .5rem;
  }
</style>
