import getVariationsForm from './getVariationsForm'
import createFormProxy from './createFormProxy'

export default async () => {
  const form = getVariationsForm()
  if (!form) return
  const $form = jQuery(form)
  if ($form.data('product_variations') === false) {
    $form.on('wc_variation_form', (event, instance) => {
      createFormProxy(instance)
    })
  }
}
