export type Gender = 'male' | 'female'
export type Unit = 'cm' | 'in' | 'us'
export interface UsSize {
  size: 'S' | 'M' | 'L' | 'XL' | 'XXL'
  gender: Gender
}

export type Size =
  | {unit: 'cm' | 'in', value: number}
  | {unit: 'us', value: UsSize}

export const US_SIZES = ['S', 'M', 'L', 'XL', 'XXL'] as const

const US_SIZES_TO_FRENCH = {
  male: {
    S: 8,
    M: 8.5,
    L: 9,
    XL: 9.5,
    XXL: 10,
  },
  female: {
    S: 6.5,
    M: 7,
    L: 7.5,
    XL: 8,
    XXL: 8.5,
  },
} as const

export function computeSize(size: Size) {
  switch (size.unit) {
    case 'cm':
      return centimetersToFrenchSize(size.value)
    case 'in':
      return inchesToFrenchSize(size.value)
    case 'us':
      return usSizeToFrenchSize(size.value)
  }
}

function inchesToFrenchSize(value: number) {
  return roundToPrecision(value, 0.5) - 0.5
}

function centimetersToFrenchSize(value: number) {
  return inchesToFrenchSize(centimetersToInches(value))
}

function usSizeToFrenchSize({gender, size}: UsSize) {
  return US_SIZES_TO_FRENCH[gender][size]
}

function centimetersToInches(value: number) {
  return value / 2.54
}

function roundToPrecision(value: number, precision: number = 1.0) {
  const inverse = 1.0 / (precision || 1.0)
  return Math.round(value * inverse) / inverse
}
