import {isTabbable} from './isFocusable'

export const attemptFocus = (el: Element) => {
  if (!isTabbable(el)) {
    return false
  }
  try {
    // @ts-ignore
    el.focus()
  } catch (err) {
    console.error(err)
  }
  return document.activeElement === el
}

export function focusFirstDescendant(el: Element) {
  for (const child of el.children) {
    if (attemptFocus(child) || focusFirstDescendant(child)) {
      return true
    }
  }
  return false
}

export function focusLastDescendant(el: Element) {
  for (let i = el.children.length - 1; i >= 0; i--) {
    const child = el.children[i]
    if (attemptFocus(child) || focusLastDescendant(child)) {
      return true
    }
  }
  return false
}
