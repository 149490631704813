import loadMessages, {type Locale} from './i18n'
import SizeCalculator from './SizeCalculator.svelte'
import {getLocale} from '../locale'

const Selectors = {
  container: '.o-sizeGuide_sizeCalculator .m-details_content',
} as const

export default async () => {
  const container = document.querySelector<HTMLElement>(Selectors.container)
  if (!container) return
  const locale = getLocale()
  const app = new SizeCalculator({
    target: container,
    props: {
      i18n: loadMessages(locale as Locale),
    }
  })
}
