// This is basically a re-implementation of the tabbable module,
// with modifications to avoid layout thrashing and add support for aria attributes

const candidateSelector = `
  input:not(:disabled):not([type="hidden"]),
  select:not(:disabled),
  textarea:not(:disabled),
  a[href],
  button,
  [tabindex],
  audio[controls],
  video[controls],
  iframe,
  [contenteditable]:not([contenteditable="false"]),
  details > summary:first-of-type,
  details
`

const hasZeroSize = (el: Element) => {
  const {width, height} = el.getBoundingClientRect()
  return width === 0 || height === 0
}

const isVisuallyHidden = (el: Element) => {
  const {display, visibility} = getComputedStyle(el)
  return display === 'none' || visibility === 'hidden'
}

const isDetailsWithSummary = (el: Element): boolean =>
  el.tagName === 'DETAILS'
  && Array.from(el.children)
    .some((node) => node.tagName === 'SUMMARY')

const isFirstDirectSummaryInsideClosedDetails = (el: Element) => {
  const isDirectSummary = el.matches('details > summary:first-of-type');
  const nodeUnderDetails = isDirectSummary ? el.parentElement : el;
  return nodeUnderDetails!.matches('details:not([open]) *');
}

const isAriaDisabledOrHidden = (el: Element) => {
  return el.getAttribute('aria-hidden') === 'true'
    || el.getAttribute('aria-disabled') === 'true'
}

const isHidden = (el: Element) => {
  return (
    isFirstDirectSummaryInsideClosedDetails(el)
    || isAriaDisabledOrHidden(el)
    || hasZeroSize(el)
    || isVisuallyHidden(el)
  )
}

const getTabIndex = (el: Element) => {
  const tabindexAttr = parseInt(el.getAttribute('tabindex') ?? '', 10)
  if (!isNaN(tabindexAttr)) {
    return tabindexAttr
  }
  const htmlEl = el as HTMLElement
  // Browsers do not return `tabIndex` correctly for contentEditable nodes;
  // so if they don't have a tabindex attribute specifically set, assume it's 0.
  if (htmlEl.contentEditable === 'true') {
    return 0
  }
  // in Chrome, <details>, <audio controls> and <video controls> elements get a default `tabIndex` of -1
  // when the 'tabindex' attribute isn't specified in the DOM, yet they are still part of the regular tab order;
  // in FF, they get a default `tabIndex` of 0;
  // since Chrome still puts those elements in the regular tab order, consider their tab index to be 0.
  if (
    (el.nodeName === 'AUDIO' ||
      el.nodeName === 'VIDEO' ||
      el.nodeName === 'DETAILS') &&
    el.getAttribute('tabindex') === null
  ) {
    return 0
  }

  return htmlEl.tabIndex
}

export function isFocusable(el: Element): boolean {
  return (
    el.matches(candidateSelector)
    && !isDetailsWithSummary(el)
    && !isHidden(el)
  )
}

export function isTabbable(el: Element): boolean {
  return isFocusable(el) && getTabIndex(el) >= 0
}
