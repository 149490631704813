import delegate from '../utils/delegate'
import {animateTo, Easings} from '../animations'
import type {ConsentType} from './consent'

type OnConsent = (type: ConsentType) => any

const STORAGE_KEY = 'mgants/cookie-consent'

const toConsentType = (value?: string): ConsentType => {
  switch (value) {
    case 'all':
    case 'essential':
      return value
    default:
      return 'essential'
  }
}

const hideDialog = async (dialog: HTMLElement) => {
  await animateTo(
    dialog,
    {transform: ['translateY(0)', 'translateY(150%)']},
    {duration: 200, easing: Easings.sharp},
  ).finished
  dialog.setAttribute('hidden', '')
}

export default (onConsent: OnConsent) => {
  const dialog = document.getElementById('cookie-consent-dialog')!
  const consentType = localStorage.getItem(STORAGE_KEY)
  if (consentType) {
    return onConsent(toConsentType(consentType))
  }

  dialog.removeAttribute('hidden')
  delegate<HTMLElement>(dialog, 'click', 'button[data-consent-type]', async ({delegateTarget}) => {
    const consentType = toConsentType(delegateTarget.dataset.consentType)
    localStorage.setItem(STORAGE_KEY, consentType)
    await hideDialog(dialog)
    onConsent(consentType)
  })
}
