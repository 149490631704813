type Options = {
  duration: number,
  easing: string,
}

export const slideDown = async (target: HTMLElement, options: Options) => {
  target.hidden = false
  const {height, paddingTop, paddingBottom, marginTop, marginBottom} = getComputedStyle(target)
  target.style.overflow = 'hidden'
  await target.animate(
    [
      {opacity: 0, height: 0, paddingTop: 0, paddingBottom: 0, marginTop: 0, marginBottom: 0},
      {opacity: 1, height, paddingTop, paddingBottom, marginTop, marginBottom},
    ],
    {...options, fill: 'none'},
  ).finished
  target.style.removeProperty('overflow')
}

export const slideUp = async (target: HTMLElement, options: Options) => {
  const {height, paddingTop, paddingBottom, marginTop, marginBottom} = getComputedStyle(target)
  target.style.overflow = 'hidden'
  await target.animate(
    [
      {opacity: 1, height, paddingTop, paddingBottom, marginTop, marginBottom},
      {opacity: 0, height: 0, paddingTop: 0, paddingBottom: 0, marginTop: 0, marginBottom: 0},
    ],
    {...options, fill: 'none'},
  ).finished
  target.hidden = true
  target.style.removeProperty('overflow')
}
