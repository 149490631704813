const getMinPossiblePrice = (form: HTMLFormElement) => {
  const input = form.elements.namedItem('min_price') as HTMLInputElement
  if (!input) return null
  return input.dataset.min
}

const getMaxPossiblePrice = (form: HTMLFormElement) => {
  const input = form.elements.namedItem('max_price') as HTMLInputElement
  if (!input) return null
  return input.dataset.max
}

function buildFilterUrl(form: HTMLFormElement): URL {
  const url = new URL(form.action)
  const data = new FormData(form)
  const search = new URLSearchParams()
  for (const [key, value] of data.entries()) {
    if (!value) continue
    if (key === 'min_price' && value === getMinPossiblePrice(form)) continue
    if (key === 'max_price' && value === getMaxPossiblePrice(form)) continue
    if (key.startsWith('qt[')) {
      const slug = key.slice(3, -1)
      if (!data.has(`${slug}[]`)) continue
    }
    search.append(key, value.toString())
  }
  url.search = search.toString()

  return url
}

export default (form: HTMLFormElement) => {
  form.addEventListener('submit', (event) => {
    event.preventDefault()
    const url = buildFilterUrl(form)
    location.href = url.toString()
  })
}
