
export const Classes = {
  focusSentinel: 'o-modalStack_focusSentinel',
  scrim: 'o-modalStack_scrim',
  bodyHasModal: '-hasModal',
  opening: '-isOpening',
  closing: '-isClosing',
  opened: '-isOpened',
}

export const Selectors = {
  focusSentinel: `.${Classes.focusSentinel}`,
  scrim: `.${Classes.scrim}`,
  closeButton: '.o-modal_close',
  document: '.o-modal_container',
  main: '.o-modal_main',
  body: '.o-modal_content',
  scrollSentinelTop: `.o-modal_scrollSentinel.-top`,
  scrollSentinelBottom: `.o-modal_scrollSentinel.-bottom`,
}
