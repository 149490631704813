import type {ProductVariation} from './types'
import createUniquePromise from '../utils/uniquePromise'

const fetchVariationsData = createUniquePromise(async (endpoint: string) => {
  const response = await fetch(endpoint, {
    headers: {accept: 'application/json'},
  })
  if (!response.ok) {
    throw new Error(response.statusText)
  }
  return response.json()
})

export default async function loadVariations(form: HTMLFormElement): Promise<ProductVariation[]> {
  const endpointUrl = form.dataset.variationsEndpoint
  if (!endpointUrl) {
    console.error('Missing variations endpoint URL.')
    return []
  }

  let variations
  try {
    variations = await fetchVariationsData(endpointUrl)
  } catch (err) {
    console.error(err)
    return []
  }

  return variations
}
