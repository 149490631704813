<script lang="ts">
  import {I18N, PostType} from './types'

  export let i18n: I18N
  export let searchTerm: string = ''
  export let postType: PostType = 'product'
  export let showBusyIndicator: boolean = false
</script>

<div class="SearchInput">
  <div class="SearchInput_searchTerm">
    <input placeholder="{i18n.searchFor}" bind:value={searchTerm}>
    {#if showBusyIndicator }
      <div class="SearchInput_busyIndicator"></div>
    {/if}
  </div>
  <div class="SearchInput_postType">
    <label class="a-formCheck">
      <input class="a-formCheck_input" type="radio" bind:group={postType} value="product">
      <span class="a-formCheck_label">{i18n.products}</span>
    </label>
    <label class="a-formCheck">
      <input class="a-formCheck_input" type="radio" bind:group={postType} value="page">
      <span class="a-formCheck_label">{i18n.pages}</span>
    </label>
  </div>
</div>

<style>
  .SearchInput {
    display: grid;
    row-gap: .5rem;
  }
  .SearchInput_searchTerm {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    column-gap: 1ch;
  }
  .SearchInput_postType {
    display: flex;
  }
  .SearchInput_postType > * + *{
    margin-left: 1ch;
  }
</style>
