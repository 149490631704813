import {Classes, Selectors} from './constants'
import {focusFirstDescendant} from '../utils/focus'
import {animateTo, Easings} from '../animations'
import {CustomEventTarget} from '../utils/CustomEventTarget'


export enum ModalEvents {
  CLOSE_REQUESTED = 'close-requested',
}

export type ModalEvent = CustomEvent<{ modal: Modal }>

interface ModalEventMap {
  [ModalEvents.CLOSE_REQUESTED]: ModalEvent
}


export class Modal extends CustomEventTarget<ModalEventMap> {
  private readonly _id: string
  private readonly _document: HTMLElement
  private readonly _scrollContainer: HTMLElement
  private readonly _body: HTMLElement
  private readonly closeButton: HTMLElement
  private readonly _scrollObserver: IntersectionObserver
  private readonly _scrollSentinels: {
    top: HTMLElement,
    bottom: HTMLElement,
  }
  private _isOpen: boolean = false
  public lastFocusedElement: Element | null = null

  constructor(
    private root: HTMLElement,
  ) {
    super()
    this._id = root.id
    this._document = root.querySelector<HTMLElement>(Selectors.document)!
    this._scrollContainer = root.querySelector<HTMLElement>(Selectors.main)!
    this._body = root.querySelector<HTMLElement>(Selectors.body)!
    this.closeButton = root.querySelector<HTMLButtonElement>(Selectors.closeButton)!
    this._scrollSentinels = {
      top: root.querySelector(Selectors.scrollSentinelTop)!,
      bottom: root.querySelector(Selectors.scrollSentinelBottom)!,
    }
    this._scrollObserver = new IntersectionObserver(this.handleScrollIntersection, {
      root: this._scrollContainer,
      rootMargin: '1px 0px 1px 0px',
    })
  }

  get id() {
    return this._id
  }

  get document() {
    return this._document
  }

  get body() {
    return this._body
  }

  get isOpen() {
    return this._isOpen
  }

  async open() {
    if (this._isOpen) {
      return
    }
    this._isOpen = true
    this.root.classList.add(Classes.opened)
    this._scrollContainer.scrollTo(0, 0)
    await this.animateEntrance()
    this.root.setAttribute('aria-hidden', 'false')
    this.closeButton.addEventListener('click', this.handleCloseButtonClicked)
    this.observeScroll()
    if (!focusFirstDescendant(this._body)) {
      this.closeButton.focus()
    }
  }

  async close() {
    if (!this._isOpen) {
      return
    }
    this._isOpen = false
    this.unobserveScroll()
    await this.animateExit()
    this.root.classList.remove(Classes.opened)
    this.closeButton.removeEventListener('click', this.handleCloseButtonClicked)
    this.root.setAttribute('aria-hidden', 'true')
  }

  private handleCloseButtonClicked = (event: Event) => {
    event.preventDefault()
    this.trigger(ModalEvents.CLOSE_REQUESTED, {modal: this})
  }

  private async animateEntrance() {
    await Promise.all([
      animateTo(this._document, {
          opacity: [0, 1],
          easing: 'linear',
        }, {duration: 75},
      ).finished,
      animateTo(this._document, {
          transform: ['scale(0.8)', 'scale(1)'],
          easing: Easings.deceleration,
        }, {duration: 150},
      ).finished,
    ])
  }

  private async animateExit() {
    await animateTo(this._document, {
        opacity: [1, 0],
        easing: 'linear',
      }, {duration: 75},
    ).finished
  }

  private observeScroll() {
    Object.values(this._scrollSentinels).forEach(sentinel => {
      this._scrollObserver.observe(sentinel)
    })
  }

  private unobserveScroll() {
    this._scrollObserver.disconnect()
  }

  private handleScrollIntersection = (records: IntersectionObserverEntry[]) => {
    for (const entry of records) {
      const {target, isIntersecting} = entry
      const hasElevation = !isIntersecting
      if (target === this._scrollSentinels.top) {
        this._document.classList.toggle('-hasElevationTop', hasElevation)
      } else {
        this._document.classList.toggle('-hasElevationBottom', hasElevation)
      }
    }
  }
}
