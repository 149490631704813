import createCollapsibles from './collapsible'
import initializeForm from './form'
import initializeSidebar from './sidebar'

export default () => {
  document.querySelectorAll('.product-filters form').forEach(form => {
    initializeForm(form as HTMLFormElement)
    createCollapsibles(form as HTMLFormElement)
  })
  initializeSidebar()
}
