import {MenuItem, type MenuItemToggledEvent} from './MenuItem'
import {Selectors} from './constants'
import modulo from '../../utils/modulo'

/**
 * Implements the WAI-ARIA menubar role for the main navigation
 * @see https://www.w3.org/TR/wai-aria-practices/examples/menubar/menubar-1/menubar-1.html
 */
export class MenuBar {
  private readonly menuItems: MenuItem[]
  private activeIndex: number = 0
  private openedMenuItems: number = 0

  constructor(
    private node: HTMLElement
  ) {
    this.menuItems = this.createMenuItems(node)
    document.body.addEventListener('click', this.handleBodyClick)
    this.node.addEventListener('keydown', this.handleKeyDown)
  }

  private childAt(position: number) {
    const index = modulo(position, this.menuItems.length)
    return this.menuItems[index]
  }

  private createMenuItems(root: HTMLElement) {
    const nodes = root.querySelectorAll<HTMLElement>(Selectors.topLevelItem)
    return Array.from(nodes).map((node, i) => {
      const item = new MenuItem(node, i === 0)
      item.on('toggled', this.handleDropDownToggled)
      item.on('focused', () => this.activeIndex = i)
      return item
    })
  }

  private handleDropDownToggled = (event: MenuItemToggledEvent) => {
    const {detail: {isOpen, menuItem}} = event
    if (isOpen) {
      this.menuItems.forEach(item => {
        if (item !== menuItem) item.close()
      })
    }
    this.openedMenuItems += isOpen ? 1 : -1
  }

  private handleBodyClick = ({target, button}: MouseEvent) => {
    if (!this.openedMenuItems || button !== 0 || !(target instanceof Node)) {
      return
    }
    this.menuItems.forEach(item => {
      if (!item.contains(target)) item.close()
    })
  }

  private handleKeyDown = (event: KeyboardEvent) => {
    const {key} = event
    switch (key) {
      case 'ArrowRight':
        event.preventDefault()
        this.moveFocusTo(this.activeIndex + 1)
        break
      case 'ArrowLeft':
        event.preventDefault()
        this.moveFocusTo(this.activeIndex - 1)
        break
      case 'Home':
      case 'PageUp':
        event.preventDefault()
        this.moveFocusTo(0)
        break
      case 'End':
      case 'PageDown':
        event.preventDefault()
        this.moveFocusTo(-1)
        break
      default:
        break
    }
  }

  private moveFocusTo(nextPosition: number) {
    const next = this.childAt(nextPosition)
    this.menuItems.forEach(item => {
      if (item === next) {
        item.focus()
      } else {
        item.blur()
      }
    })
  }
}
