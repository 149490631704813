
export const Classes = {
  menuBar: 'o-siteNav_mainMenu',
  item: 'o-siteNav_menuItem',
  subMenu: 'o-siteNav_subMenu',
  isTopLevel: '-isTopLevel',
  hasChildren: '-hasChildren',
  isOpen: `-isOpen`,
}

export const Selectors = {
  menuBar: `.${Classes.menuBar}`,
  item: `.${Classes.item}`,
  subMenu: `.${Classes.subMenu}`,
  menu: `[role=menu]`,
  menuItem: `[role=menuitem]`,
  topLevelItem: `.${Classes.item}.${Classes.isTopLevel}`,
  topLevelMenuItem: `.${Classes.item}.${Classes.isTopLevel} > [role=menuitem]`,
  dropdownMenu: `.${Classes.item}.${Classes.isTopLevel}.${Classes.hasChildren}`,
}
