import type {AsyncReturnType, PromiseFactory} from './types'

/**
 * Takes a function returning a promise and returns a function
 * that keeps returning this very same promise until it settles.
 */
export default function createUniquePromise<F extends PromiseFactory>(fn: F) {
  let promise: Promise<AsyncReturnType<F>>|null
  return (...args: Parameters<F>) => {
    if (!promise) {
      promise = fn(...args).finally(() => promise = null)
    }
    return promise
  }
}
