import {Selectors} from './selectors'
import {TabsComponent} from './TabsComponent'

const tabsInstances = new WeakMap<HTMLElement, TabsComponent>()
const createTabComponent = (node: HTMLElement): TabsComponent => {
  if (!tabsInstances.has(node)) {
    tabsInstances.set(node, new TabsComponent(node))
  }
  return tabsInstances.get(node)!
}

export default () => {
  document.querySelectorAll<HTMLElement>(Selectors.tabsComponent).forEach(async (node) => {
    const tabs = createTabComponent(node)
    await tabs.activateInitialPanel()
  })
}
