import {CustomEventTarget} from '../../utils/CustomEventTarget'
import {Classes, Selectors} from './constants'
import modulo from '../../utils/modulo'

export type MenuItemToggledEvent = CustomEvent<{menuItem: MenuItem, isOpen: boolean}>
export type MenuItemFocusedEvent = CustomEvent<{menuItem: MenuItem}>
interface MenuItemEventMap {
  toggled: MenuItemToggledEvent
  focused: MenuItemFocusedEvent
}

export class MenuItem extends CustomEventTarget<MenuItemEventMap> {
  private readonly menuItem: HTMLElement
  private readonly menu: HTMLElement|null
  private readonly children: HTMLElement[] = []
  private activeIndex: number = 0

  constructor(
    private readonly node: HTMLElement,
    receivesInitialFocus: boolean = false,
  ) {
    super()
    const menuItem = node.querySelector<HTMLElement>(Selectors.menuItem)
    if (!menuItem) {
      throw new Error(`Missing required element: ${Selectors.menuItem}`)
    }
    this.menuItem = menuItem
    this.menuItem.tabIndex = receivesInitialFocus ? 0 : -1
    this.menuItem.addEventListener('focus', this.handleMenuItemFocus)
    this.menu = node.querySelector<HTMLElement>(Selectors.subMenu)
    this.node.addEventListener('keydown', this.handleKeyDown)
    if (this.menu) {
      this.menuItem.setAttribute('aria-haspopup', 'true')
      this.menuItem.setAttribute('aria-expanded', 'false')
      this.children = Array.from(this.menu.querySelectorAll(Selectors.menuItem))
      this.menu.addEventListener('focusin', this.handleSubMenuFocusIn)
      this.menuItem.addEventListener('click', this.handleMenuItemClicked)
    }
  }

  get hasChildren(): boolean {
    return this.children.length > 0
  }

  get isOpen(): boolean {
    return this.node.classList.contains(Classes.isOpen)
  }

  contains(node: Node|null): boolean {
    return this.node.contains(node)
  }

  focus() {
    this.menuItem.tabIndex = 0
    this.menuItem.focus()
    this.open()
  }

  blur() {
    this.menuItem.tabIndex = -1
    this.close()
  }

  open() {
    this.toggle(true)
  }

  close() {
    this.toggle(false)
  }

  toggle(open?: boolean) {
    if (!this.hasChildren) {
      return
    }
    const wasOpen = this.isOpen
    const isOpen = this.node.classList.toggle(Classes.isOpen, open)
    if (isOpen !== wasOpen) {
      this.menuItem.setAttribute('aria-expanded', isOpen ? 'true' : 'false')
      this.trigger('toggled', {isOpen, menuItem: this})
    }
  }

  private handleMenuItemFocus = () => {
    this.trigger('focused', {menuItem: this})
  }

  private handleSubMenuFocusIn = (event: FocusEvent) => {
    if (!event.target) {
      return
    }
    event.stopPropagation()
    this.activeIndex = this.children.indexOf(event.target as HTMLElement)
  }

  private handleMenuItemClicked = (event: MouseEvent) => {
    event.preventDefault()
    this.toggle()
  }

  private handleKeyDown = (event: KeyboardEvent) => {
    const {key} = event
    let target = event.target as HTMLElement
    switch (key) {
      case 'Space':
      case ' ':
      case 'Enter':
        event.preventDefault()
        if (target === this.menuItem) {
          this.toggle()
          return
        }
        target.click()
        break;
      case 'Tab':
        this.close()
        break
      case 'Escape':
        event.preventDefault()
        this.close()
        this.menuItem.focus()
        break
      case 'ArrowDown':
        event.preventDefault()
        if (target === this.menuItem) {
          this.open()
          this.setFocusAt(0)
          return
        }
        this.focusNext()
        break
      case 'ArrowUp':
        event.preventDefault()
        if (target === this.menuItem) {
          this.open()
          this.setFocusAt(-1)
          return
        }
        this.focusPrevious()
        break
      case 'Home':
      case 'PageUp':
        if (target === this.menuItem) return
        event.preventDefault()
        event.stopPropagation()
        this.setFocusAt(0)
        break
      case 'End':
      case 'PageDown':
        if (target === this.menuItem) return
        event.preventDefault()
        event.stopPropagation()
        this.setFocusAt(-1)
        break
      default:
        // console.log(key)
        break
    }
  }

  private childAt(position: number): HTMLElement|null {
    const index = modulo(position, this.children.length)
    return this.children[index] ?? null
  }

  private setFocusAt(position: number) {
    const next = this.childAt(position)
    if (next) {
      next.focus()
    }
  }

  private focusNext() {
    this.setFocusAt(this.activeIndex + 1)
  }

  private focusPrevious() {
    this.setFocusAt(this.activeIndex - 1)
  }
}
