import {MenuBar} from './MenuBar'
import {Selectors} from './constants'

export default () => {
  const menuBar = document.querySelector<HTMLElement>(Selectors.menuBar)
  if (!menuBar) {
    throw new Error(`Menubar not found with selector "${Selectors.menuBar}"`)
  }
  new MenuBar(menuBar)
}
