<script lang="ts">
  import type {Messages} from './i18n'
  import type {Size, Unit} from './conversions'
  import UsSizeInput from './UsSizeInput.svelte'
  import NumericSizeInput from './NumericSizeInput.svelte'

  export let i18n: Messages

  let unit: Unit = 'cm'
  let size: Size = {
    unit,
    value: 20,
  }
</script>

<div class="o-gloveSizeCalculator">

  <label>
    <span>{i18n.unit}</span>
    <select bind:value={unit}>
      {#each ['cm', 'in', 'us'] as unit }
        <option value={unit}>{i18n.units[unit]}</option>
      {/each}
    </select>
  </label>

  {#if unit === 'us'}
    <UsSizeInput i18n={i18n} bind:size={size} />
  {:else}
    <NumericSizeInput i18n={i18n} bind:unit={unit} bind:size={size} />
  {/if}

  <div>
    {i18n.gloveSize}
    <b>{size}</b>
  </div>

</div>

<style>
  .o-gloveSizeCalculator {
    display: flex;
    gap: 1rem;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
  }
</style>
