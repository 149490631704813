import jQuery from 'jquery'

const Classes = {
  form: 'variations_form',
  container: 'variation-attribute--has-color-swatch',
  swatchInput: 'color-swatch-control__input',
  valueIndicator: 'color-swatch-value',
}

const Selectors = {
  form: `.${Classes.form}`,
  container: `.${Classes.container}`,
  swatchInput: `.${Classes.swatchInput}`,
  select: `select`,
  valueIndicator: `.${Classes.valueIndicator}`,
}

type ValueMap = Record<string, HTMLInputElement>

class SwatchVariationWidget {
  private readonly form: HTMLFormElement
  private readonly select: HTMLSelectElement
  private readonly valueIndicator: HTMLElement
  private readonly inputs: HTMLInputElement[]
  private inputsByValue: ValueMap

  constructor(
    private container: HTMLElement,
  ) {
    this.form = this.container.closest<HTMLFormElement>(Selectors.form)!
    this.select = this.container.querySelector<HTMLSelectElement>(Selectors.select)!
    this.inputs = Array.from(this.container.querySelectorAll<HTMLInputElement>(Selectors.swatchInput))
    this.inputsByValue = this.inputs.reduce<ValueMap>((acc, input) => {
      acc[input.value] = input
      return acc
    }, {})
    this.valueIndicator = document.createElement('div')
    this.valueIndicator.className = Classes.valueIndicator
    this.container.querySelector('.value')?.prepend(this.valueIndicator)
    this.updateValueIndicator()

    jQuery(this.form)
      .on('click', '.reset_variations', () => this.reset())
      .on('woocommerce_update_variation_values', () => this.update())
      .on('change', Selectors.swatchInput, (event) => {
        jQuery(this.select)
          .val(event.target.value)
          .trigger('change')
      })
  }

  private reset() {
    this.inputs.forEach(input => input.checked = false)
  }

  /**
   * Synchronize our radio inputs with the hidden select managed by woocommerce.
   * @private
   */
  private update() {
    for (const input of this.inputs) {
      Object.assign(input, {checked: false, disabled: true})
    }
    for (const option of this.select.options) {
      if (option.classList.contains('enabled')) {
        this.inputsByValue[option.value].disabled = false
      }
    }
    const {value} = this.select
    if (value) {
      this.inputsByValue[value].checked = true
    }
    this.updateValueIndicator()
  }

  private updateValueIndicator() {
    const {value} = this.select
    this.valueIndicator.innerHTML = this.select.querySelector(`[value="${value}"]`)?.textContent?.trim() ?? ''
  }
}

const instances = new WeakMap()
const createInstance = (element: HTMLElement) => {
  if (!instances.has(element)) {
    instances.set(element, new SwatchVariationWidget(element))
  }
}

export default () => {
  // we have to use delegation, since the form can be loaded in a quick-view modal
  const $doc = jQuery(document.body)
  $doc.on('wc_variation_form', (event) => {
    event.target.querySelectorAll<HTMLElement>(Selectors.container)
      .forEach(el => createInstance(el))
  })
}
