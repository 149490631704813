type Sentry = typeof import('@sentry/browser')
import type {BrowserOptions} from '@sentry/browser'

declare global {
  interface Window {
    SENTRY_CONFIG?: BrowserOptions
  }
}

let instance: Sentry|null = null

export const getSentry = async () => {
  if (instance) {
    return instance
  }
  const {SENTRY_CONFIG = {}} = window
  const [Sentry, Tracing] = await Promise.all([
    import('@sentry/browser'),
    import('@sentry/tracing'),
  ])
  instance = Sentry
  instance.init({
    ...SENTRY_CONFIG,
    integrations: [
      new Tracing.BrowserTracing(),
    ],
  })

  return instance
}

export default () => {
  window.onerror = async (message, url, line, column, error) => {
    try {
      const {captureException} = await getSentry()
      captureException(error)
    } catch (err) {
      window.onerror = null
      console.error(`Logging to Sentry failed: ${err}`)
    }
  }
  window.onunhandledrejection = async (event) => {
    try {
      const {captureException} = await getSentry()
      captureException(event.reason)
    } catch (err) {
      window.onunhandledrejection = null
      console.error(`Logging to Sentry failed: ${err}`)
    }
  }
}
