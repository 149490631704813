import type {SearchResult} from './types'
import createAbortableFetch from '../utils/abortableFetch'

const abortable = createAbortableFetch(300)

export async function fetchResults(
  endpoint: string,
  term: string,
  postType: string = 'product'
): Promise<SearchResult[]> {
  const url = new URL(endpoint)
  url.searchParams.set('s', term)
  url.searchParams.set('t', postType)
  const response = await abortable.fetch(url.href, {
    headers: {accept: 'application/json'},
  })
  if (!response.ok) {
    throw new Error(response.statusText)
  }
  return response.json()
}
