import {Classes, Selectors} from './selectors'
import {CustomEventTarget} from '../utils/CustomEventTarget'
import {Easings} from '../animations'

export type TabActivateEvent = CustomEvent<{tab: Tab}>
export type TabFocusedEvent = CustomEvent<{tab: Tab}>
interface TabEventMap {
  activate: TabActivateEvent
  focused: TabFocusedEvent
}

function createIndicator(node: HTMLElement): HTMLElement {
  let indicator = node.querySelector<HTMLElement>(Selectors.activeTabIndicator)
  if (!indicator) {
    indicator = document.createElement('span')
    indicator.classList.add(Classes.activeTabIndicator)
    node.appendChild(indicator)
  }
  return indicator
}

export class Tab extends CustomEventTarget<TabEventMap> {
  private indicator: HTMLElement

  constructor(
    private node: HTMLElement,
    receivesInitialFocus: boolean = false
  ) {
    super()
    this.indicator = createIndicator(this.node)
    this.node.tabIndex = receivesInitialFocus ? 0 : -1
    this.node.addEventListener('click', (event) => {
      event.preventDefault()
      this.trigger('activate', {tab: this})
    })
    this.node.addEventListener('keydown', this.handleKeyDown)
    this.node.addEventListener('focus', () => {
      this.trigger('focused', {tab: this})
    })
    this.node.addEventListener('blur', () => {
      this.node.tabIndex = -1
    })
  }

  get controls() {
    return this.node.getAttribute('aria-controls') ?? null
  }

  get isActive() {
    return this.node.classList.contains(Classes.activeTab)
  }

  getClientRect() {
    return this.node.getBoundingClientRect()
  }

  focus() {
    this.node.tabIndex = 0
    this.node.focus()
  }

  deactivate() {
    this.node.classList.remove(Classes.activeTab)
    this.node.setAttribute('aria-selected', 'false')
    // this.node.tabIndex = -1
  }

  async activate(previousTab: Tab|null) {
    this.node.setAttribute('aria-selected', 'true')
    if (!previousTab) {
      this.node.classList.add(Classes.activeTab)
      return
    }
    const previousRect = previousTab.getClientRect()
    const nextRect = this.node.getBoundingClientRect()
    const diff = {
      translateX: previousRect.left - nextRect.left,
      translateY: previousRect.top - nextRect.top,
      scaleX: previousRect.width / nextRect.width,
      scaleY: previousRect.height / nextRect.height,
    }
    this.node.classList.add(Classes.activeTab)
    await this.indicator.animate({
      transform: [
        `translate(${diff.translateX}px, ${diff.translateY}px) scale(${diff.scaleX}, ${diff.scaleY})`,
        'translate(0px, 0px) scale(1, 1)',
      ],
    }, {duration: 250, easing: Easings.standard}).finished
  }

  private handleKeyDown = (event: KeyboardEvent) => {
    const {key} = event
    switch (key) {
      case 'Space':
      case ' ':
      case 'Enter':
        event.preventDefault()
        this.trigger('activate', {tab: this})
        break;
    }
  }
}
