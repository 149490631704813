type ExtractEventDetail<T> = T extends CustomEvent<infer X> ? X : never

export class CustomEventTarget<TEventMap> extends EventTarget {
  on<K extends keyof TEventMap>(
    type: K,
    listener: (ev: TEventMap[K]) => void,
    options?: AddEventListenerOptions,
  ): void {
    super.addEventListener(type as string, listener as unknown as EventListener, options)
  }

  off<K extends keyof TEventMap>(
    type: K,
    listener: (ev: TEventMap[K]) => void,
    options?: EventListenerOptions,
  ): void {
    super.removeEventListener(type as string, listener as unknown as EventListener, options)
  }

  once<K extends keyof TEventMap>(
    type: K,
    listener: (ev: TEventMap[K]) => void,
    options?: AddEventListenerOptions,
  ): void {
    super.addEventListener(type as string, listener as unknown as EventListener, {
      ...options,
      once: true,
    })
  }

  trigger<K extends keyof TEventMap>(
    type: K,
    detail: ExtractEventDetail<TEventMap[K]>,
    options?: EventInit,
  ): boolean {
    return super.dispatchEvent(new CustomEvent(type as string, {
      ...options,
      detail,
    }))
  }
}
