import {Modal, type ModalEvent, ModalEvents} from './Modal'
import {Classes, Selectors} from './constants'
import {focusFirstDescendant, focusLastDescendant} from '../utils/focus'
import {animateTo, Easings} from '../animations'
import {CustomEventTarget} from '../utils/CustomEventTarget'


export enum ModalManagerEvents {
  OPEN = 'open',
  OPENED = 'opened',
  CLOSE = 'close',
  CLOSED = 'closed',
}
export type ModalManagerEvent = CustomEvent<{ dialog: Modal }>
interface ModalManagerEventMap {
  [ModalManagerEvents.OPEN]: ModalManagerEvent
  [ModalManagerEvents.OPENED]: ModalManagerEvent
  [ModalManagerEvents.CLOSE]: ModalManagerEvent
  [ModalManagerEvents.CLOSED]: ModalManagerEvent
}


export interface ModalManagerEventListener {
  (event: ModalManagerEvent): any;
}


const createFocusSentinel = (): HTMLElement => {
  const sentinel = document.createElement('div')
  sentinel.setAttribute('tabindex', '0')
  sentinel.setAttribute('aria-hidden', 'true')
  sentinel.classList.add(Classes.focusSentinel)
  return sentinel
}


export class ModalManager extends CustomEventTarget<ModalManagerEventMap> {
  private readonly modalsById: Map<string, Modal> = new Map()
  private readonly openedStack: Modal[] = []
  private readonly scrim: HTMLElement
  private isHandlingFocus: boolean = false
  private initialFocusNode: Element|null = null

  constructor(
    private stackArea: HTMLElement
  ) {
    super()
    this.scrim = document.createElement('div')
    this.scrim.classList.add(Classes.scrim)
    this.stackArea.prepend(this.scrim)
    this.scrim.addEventListener('click', this.handleScrimClicked)
  }

  get hasDialog(): boolean {
    return this.openedStack.length > 0
  }

  get current(): Modal|null {
    return this.openedStack[this.openedStack.length - 1] ?? null
  }

  async open(modalId: string): Promise<Modal> {
    const dialog = this.retrieveOrCreateModal(modalId)
    const accepted = this.trigger(ModalManagerEvents.OPEN, {dialog}, {cancelable: true})
    if (!accepted) {
      return Promise.reject()
    }

    if (!this.hasDialog) {
      this.initialFocusNode = document.activeElement
      this.addFocusSentinels()
      document.addEventListener('focus', this.handleFocus, true)
      document.addEventListener('keyup', this.handleKeyUp)
      document.body.classList.add(Classes.bodyHasModal)
    }
    this.openedStack.push(dialog)
    await Promise.all([
      dialog.open(),
      this.showScrim(),
    ])
    this.trigger(ModalManagerEvents.OPENED, {dialog})

    return dialog
  }

  async close(modalId: string): Promise<Modal> {
    const dialog = this.modalsById.get(modalId)
    if (!dialog) {
      throw new Error(`No modal found with id: ${modalId}.`)
    }
    const accepted = this.trigger(ModalManagerEvents.CLOSE, {dialog}, {cancelable: true})
    if (!accepted) {
      return Promise.reject()
    }

    this.openedStack.pop()
    if (!this.openedStack.length) {
      document.body.classList.remove(Classes.bodyHasModal)
      document.removeEventListener('focus', this.handleFocus, true)
      document.removeEventListener('keyup', this.handleKeyUp)
      this.removeFocusSentinels()
    }
    await Promise.all([
      dialog.close(),
      this.hideScrim(),
    ])
    if (this.initialFocusNode instanceof HTMLElement) {
      this.initialFocusNode.focus()
    }

    this.trigger(ModalManagerEvents.CLOSED, {dialog})
    return dialog
  }

  private async showScrim() {
    if (this.openedStack.length > 1) {
      return
    }
    await animateTo(this.scrim, {opacity: [0, 1], easing: Easings.standard}, {duration: 300})
      .finished
  }

  private async hideScrim() {
    if (this.openedStack.length) {
      return
    }
    await animateTo(this.scrim, {opacity: [1, 0], easing: Easings.standard}, {duration: 300})
      .finished
  }

  private retrieveOrCreateModal(modalId: string): Modal {
    if (!this.modalsById.has(modalId)) {
      const node = document.getElementById(modalId)
      if (!node) {
        throw new Error(`No modal found with id: ${modalId}`)
      }
      this.appendToStackArea(node)
      const modal = new Modal(node)
      modal.on(ModalEvents.CLOSE_REQUESTED, this.handleCloseRequested)
      this.modalsById.set(modalId, modal)
    }
    return this.modalsById.get(modalId)!
  }

  private handleFocus = (event: FocusEvent) => {
    if (this.isHandlingFocus || !(event?.target instanceof Element)) {
      return
    }
    const current = this.current
    if (!current) return
    this.isHandlingFocus = true
    const target = event.target
    if (current.document.contains(target)) {
      current.lastFocusedElement = target
    } else {
      focusFirstDescendant(current.document)
      if (current.lastFocusedElement === document.activeElement) {
        focusLastDescendant(current.document)
      }
      current.lastFocusedElement = document.activeElement
    }
    this.isHandlingFocus = false
  }

  private handleScrimClicked = async () => {
    const modal = this.current
    if (modal) {
      await this.close(modal.id)
    }
  }

  private handleKeyUp = async (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      const modal = this.current
      if (modal) {
        await this.close(modal.id)
      }
    }
  }

  private handleCloseRequested = async (event: ModalEvent) => {
    await this.close(event.detail.modal.id)
  }

  private appendToStackArea(node: Element) {
    if (!this.stackArea.contains(node)) {
      const sentinel = this.stackArea.querySelector(`${Selectors.focusSentinel}:last-of-type`)
      this.stackArea.insertBefore(node, sentinel)
    }
  }

  private addFocusSentinels() {
    const sentinelTop = createFocusSentinel()
    const sentinelBottom = createFocusSentinel()

    sentinelTop.addEventListener('focus', (event) => {
      const current = this.current
      if (!current) return
      focusLastDescendant(current.document)
    })
    sentinelBottom.addEventListener('focus', (event) => {
      const current = this.current
      if (!current) return
      focusFirstDescendant(current.document)
    })

    this.stackArea.prepend(sentinelTop)
    this.stackArea.append(sentinelBottom)
  }

  private removeFocusSentinels() {
    this.stackArea.querySelectorAll(Selectors.focusSentinel)
      .forEach(el => el.remove())
  }
}
