
export type DelegateEvent<
  TEvent extends Event = Event,
  TElement extends Element = Element,
> = TEvent & {delegateTarget: TElement}

export type DelegateListener<
  TEvent extends Event = Event,
  TElement extends Element = Element,
> = (event: DelegateEvent<TEvent, TElement>) => any


function getDelegateTarget(root: Element, event: Event, selector: string) {
  const path = event.composedPath()
  const index = path.indexOf(root)
  if (index === -1) {
    return null
  }
  for (const target of path) {
    if (target instanceof Element && target.matches(selector)) {
      return target
    }
  }
  return null
}

export default function delegate<
  TElement extends Element = Element,
  TEvent extends Event = Event,
>(
  element: Element|Document,
  eventName: string,
  selector: string,
  listener: DelegateListener<TEvent, TElement>,
  options?: AddEventListenerOptions,
): VoidFunction {
  const subject = element instanceof Document ? element.documentElement : element
  const handler: EventListener = (event: Event) => {
    const target = getDelegateTarget(subject, event, selector)
    if (target) {
      (event as any).delegateTarget = target
      return listener(event as DelegateEvent<TEvent, TElement>)
    }
  }
  subject.addEventListener(eventName, handler, options)
  return () => subject.removeEventListener(eventName, handler, options)
}
