import SearchForm from './SearchForm.svelte'
import {fetchResults} from './fetchResults'
import i18n from './i18n.json'

type Settings = {
  endpoint: string,
  locale: 'en' | 'fr'
}

const getComponentData = (node: HTMLElement): Settings => {
  const script = node.querySelector<HTMLScriptElement>('script[type="application/json"]')
  if (!script || !script.textContent) {
    throw new Error('Missing search-form initial data.')
  }
  return JSON.parse(script.textContent)
}

export default () => {
  const node = document.querySelector<HTMLElement>('#search-dialog .o-modal_content')
  if (!node) {
    throw new Error('Missing search-form node.')
  }
  const {endpoint, locale} = getComponentData(node)
  const component = new SearchForm({
    target: node,
    props: {
      minCharacters: 2,
      fetchResults: fetchResults.bind(null, endpoint),
      i18n: i18n[locale] ?? i18n.en,
    },
  })
}
