import {slideDown, slideUp} from '../animations/slide'
import {Easings} from '../animations'

const Classes = {
  collapsible: 'product-filter--collapsible',
  collapsed: 'product-filter--collapsed',
}
const Selectors = {
  collapsible: `.${Classes.collapsible}`,
  toggler: '.product-filter-header button',
  target: '.product-filter-wrapper',
}

const animationOptions = {
  duration: 250,
  easing: Easings.standard,
}

class Collapsible {

  private toggler: HTMLElement
  private target: HTMLElement
  private isTransitioning: boolean

  constructor(
    private container: HTMLElement,
  ) {
    this.isTransitioning = false
    this.target = this.container.querySelector(Selectors.target) as HTMLElement
    this.toggler = this.container.querySelector(`[aria-controls="${this.target.id}"]`) as HTMLElement
    this.setInitialState()
    this.toggler.addEventListener('click', async (event) => {
      event.preventDefault()
      await this.toggle()
    })
  }

  private setInitialState() {
    const collapsed = this.isCollapsed && !this.containsSelectedInputs
    this.target.hidden = collapsed
    this.setCollapsed(collapsed)
  }

  get isCollapsed() {
    return this.container.classList.contains(Classes.collapsed)
  }

  get containsSelectedInputs() {
    return !!this.target.querySelectorAll(':checked, option[selected]').length
  }

  async toggle() {
    await (this.isCollapsed ? this.show() : this.hide())
  }

  async show() {
    if (this.isTransitioning) return
    this.isTransitioning = true
    await slideDown(this.target, animationOptions)
    this.setCollapsed(false)
    this.isTransitioning = false
  }

  async hide() {
    if (this.isTransitioning) return
    this.isTransitioning = true
    await slideUp(this.target, animationOptions)
    this.setCollapsed(true)
    this.isTransitioning = false
  }

  private setCollapsed(collapsed: boolean) {
    this.container.classList.toggle(Classes.collapsed, collapsed)
    this.toggler.setAttribute('aria-expanded', collapsed ? 'false' : 'true')
  }
}

export default (container: HTMLElement) => {
  container.querySelectorAll<HTMLElement>(Selectors.collapsible)
    .forEach((el) => new Collapsible(el))
}
