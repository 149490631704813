import jQuery from 'jquery'

import type {ProductVariation} from './types'
import loadVariations from './loadVariations'

const variationsLookup = new WeakMap<HTMLElement, ProductVariation[]>()

export default async function getVariations(form: HTMLFormElement): Promise<ProductVariation[]> {
  let variations = variationsLookup.get(form)
  if (variations) {
    return variations
  }

  variations = jQuery(form).data('product_variations')
  if (variations) {
    variationsLookup.set(form, variations)
    return variations
  }

  variations = await loadVariations(form)
  variationsLookup.set(form, variations)
  return variations
}
