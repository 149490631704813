import {animateTo, Easings} from '../animations'

const Classes = {
  active: '-active',
}
const Selectors = {
  button: '.m-wcLoop_layoutSelectorButton',
  layoutContainer: '.o-wcLoop.-isShop, .o-wcLoop.-isTax'
}

type LayoutPref = 'grid' | 'list'
type ColumnsPref = 1 | 2 | 3 | 4

interface Preferences {
  layoutType: LayoutPref
  columns: ColumnsPref
}

const DEFAULTS: Preferences = Object.freeze({
  layoutType: 'grid',
  columns: 3,
})

const STORAGE_KEY = 'loopLayout'

const getLayoutPref = (value: any): LayoutPref => {
  if (value === 'grid' || value === 'list') return value
  return DEFAULTS.layoutType
}
const getColumnsPref = (value: any): ColumnsPref => {
  value = Number(value)
  if (!value) return DEFAULTS.columns
  return Math.max(1, Math.min(4, value)) as ColumnsPref
}
const getPrefs = (value: any): Preferences => {
  return {
    layoutType: getLayoutPref(value.layoutType),
    columns: getColumnsPref(value.columns),
  }
}

function loadPreferences(): Preferences {
  try {
    const prefs = JSON.parse(localStorage.getItem(STORAGE_KEY) ?? '{}')
    return getPrefs(prefs)
  } catch (err) {
    return DEFAULTS
  }
}

function savePreferences(prefs: Preferences): void {
  localStorage.setItem(STORAGE_KEY, JSON.stringify(prefs))
}

async function setLayout(prefs: Preferences) {
  const el = document.querySelector<HTMLElement>(Selectors.layoutContainer)
  if (!el) return
  await animateTo(el, {opacity: 0}, {duration: 250, easing: Easings.standard}).finished
  Object.assign(el.dataset, prefs)
  await animateTo(el, {opacity: 1}, {duration: 600, easing: Easings.deceleration}).finished
}

function isActiveButtonForPrefs(btn: HTMLButtonElement, prefs: Preferences): boolean {
  const {layoutType, columns} = getPrefs(btn.dataset)
  return layoutType === prefs.layoutType && columns === prefs.columns
}

function setActiveButtons(btns: NodeListOf<HTMLButtonElement>, prefs: Preferences) {
  btns.forEach(btn => {
    const isActive = isActiveButtonForPrefs(btn, prefs)
    btn.classList.toggle(Classes.active, isActive)
    btn.disabled = isActive
  })
}


export default () => {
  const loop = document.querySelector<HTMLElement>(Selectors.layoutContainer)
  if (!loop) return

  const prefs = loadPreferences()
  Object.assign(loop.dataset, prefs)

  const buttons = document.querySelectorAll<HTMLButtonElement>(Selectors.button)
  setActiveButtons(buttons, prefs)

  buttons.forEach(btn => {
    btn.addEventListener('click', async () => {
      const prefs = getPrefs(btn.dataset)
      savePreferences(prefs)
      setActiveButtons(buttons, prefs)
      await setLayout(prefs)
    })
  })
}
