
export default async (selector: string) => {
  const nodes = document.querySelectorAll<HTMLElement>(selector)
  if (nodes.length) {
    const {createCarousel} = await import('./createCarousel')
    nodes.forEach(container => {
      createCarousel(container)
    })
  }
}
