import type {AnyFunction, AsyncReturnType} from './types'

const noop = () => {}

function debounceFetch<T extends AnyFunction>(
  fn: T,
  delay: number,
  onCancel: AnyFunction = noop
) {
  let timer = 0
  let isFetching = false

  return (...args: Parameters<T>) => new Promise<AsyncReturnType<T>>((resolve, reject) => {
    clearTimeout(timer)
    if (isFetching) {
      onCancel()
      isFetching = false
    }
    timer = window.setTimeout(() => {
      isFetching = true
      fn(...args).then(resolve, reject)
    }, delay)
  })
}

export default (delay: number = 300) => {
  let controller = new AbortController()
  const abort = () => controller.abort()
  const doFetch = (info: RequestInfo, init: RequestInit = {}) => {
    controller = new AbortController()
    return fetch(info, {...init, signal: controller.signal})
  }
  return {
    fetch: debounceFetch(doFetch, delay, abort),
    abort,
  }
}
