<script lang="ts">

  import {computeSize} from './conversions'
  import type {Messages} from './i18n'

  export let unit: 'cm' | 'in' = 'cm'
  export let i18n: Messages
  let value: number = 20.0
  export let size

  $: min = unit === 'cm' ? 12 : 5
  $: max = unit === 'cm' ? 30 : 12

  $: size = computeSize({unit, value})

</script>

<label>
  <span>{i18n.size}</span>
  <input type="number" min={min} max={max} step="0.5" bind:value={value}>
</label>
