import getVariations from './getVariations'
import type {AnyFunction} from '../utils/types'


function withTemporaryProps<T extends object>(obj: T, props: Partial<T>, fn: AnyFunction) {
  const backup: Partial<T> = Reflect.ownKeys(props)
    .reduce((acc, k) => ({...acc, [k]: Reflect.get(obj, k)}), {})
  Object.assign(obj, props)
  try {
    fn()
  } finally {
    Object.assign(obj, backup)
  }
}


export default async (instance: any) => {
  const {$form} = instance

  const onUpdateAttributes = instance.onUpdateAttributes
  instance.onUpdateAttributes = async (event: any) => {
    const variationData = await getVariations($form[0])
    withTemporaryProps(instance, {useAjax: false, variationData}, () => {
      onUpdateAttributes.call(instance, event)
    })
  }

  $form
    .off('update_variation_values.wc-variation-form', onUpdateAttributes)
    .on('update_variation_values.wc-variation-form', {variationForm: instance}, instance.onUpdateAttributes)
    .on('found_variation', () => $form.trigger('update_variation_values'))


  return instance
}
