
const Classes = {
  isScrolled: '-isScrolled',
}
const Selectors = {
  navBar: '#navbar',
}

export default () => {
  const navBar = document.querySelector(Selectors.navBar)
  const observer = new IntersectionObserver((records) => {
    for (const entry of records) {
      const isScrolled = entry.boundingClientRect.y === 0
      entry.target.classList.toggle(Classes.isScrolled, isScrolled)
    }
  }, {rootMargin: '-1px 0px 0px 0px', threshold: [1]})
  observer.observe(navBar!)
}
