import {Classes} from './selectors'
import {Easings} from '../animations'

export class TabPanel {
  constructor(
    private node: HTMLElement,
  ) {
    if (!node.id) {
      throw new Error('Missing required attribute "id" for tabpanel element.')
    }
  }

  get id() {
    return this.node.id
  }

  contains(node: Node|null) {
    return this.node.contains(node)
  }

  get isActive() {
    return this.node.classList.contains(Classes.activePanel)
  }

  async activate() {
    if (this.isActive) return
    this.node.classList.add(Classes.activePanel)
    await this.node.animate({opacity: [0, 1]}, {duration: 150, easing: Easings.standard}).finished
  }

  async deactivate() {
    if (!this.isActive) return
    await this.node.animate({opacity: [1, 0]}, {duration: 150, easing: Easings.standard}).finished
    this.node.classList.remove(Classes.activePanel)
  }
}
