<script lang="ts">
  export let message: string
</script>

<div class="EmptyState">
  <div class="EmptyState_message">
    {message}
  </div>
</div>

<style>
  .EmptyState {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .EmptyState_message {
    padding: 1rem;
  }
</style>
