
export const Classes = {
  loadButton: 'load-quick-view',
  loading: '-loading',
  imageData: 'o-productQuickView_imageData',
  imageGallery: 'o-productQuickView_gallery',
}

export const Selectors = {
  loadButton: `.${Classes.loadButton}`,
  imageData: `.${Classes.imageData}`,
  imageGallery: `.${Classes.imageGallery}`,
}
