import delegate from '../../utils/delegate'

const Classes = {
  isOpen: '-isOpen',
}

const Selectors = {
  root: '.o-mobileNav',
  menu: '.o-mobileNav_menu',
  menuItem: '.o-mobileNav_menuItem',
  subMenu: '.o-mobileNav_subMenu',
  subMenuToggle: '.o-mobileNav_menuItem.-hasChildren > a',
}


export default () => {
  /**
   * @TODO Keyboard navigation & a11y checks
   */
  const root = document.querySelector(Selectors.root) as HTMLElement
  delegate(root, 'click', Selectors.subMenuToggle, (event) => {
    event.preventDefault()
    const toggler = event.delegateTarget
    const menuItem = toggler.closest(Selectors.menuItem) as HTMLElement
    const submenu = menuItem.querySelector(Selectors.subMenu) as HTMLElement
    submenu.classList.add(Classes.isOpen)
  })
  delegate(root, 'click', '.o-mobileNav_menuItem.-isBackLink', (event) => {
    event.preventDefault()
    const toggler = event.delegateTarget
    const menuItem = toggler.closest(Selectors.menuItem) as HTMLElement
    const submenu = menuItem.closest(Selectors.subMenu) as HTMLElement
    submenu.classList.remove(Classes.isOpen)
  })
}
