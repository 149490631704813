import {Selectors} from './selectors'
import {TabActivatedEvent, TabList} from './TabList'
import {TabPanel} from './TabPanel'
import getLocationHash from '../utils/getLocationHash'

const createTabList = (node: HTMLElement): TabList => {
  const tabListNode = node.querySelector<HTMLElement>(Selectors.tabList)
  if (!tabListNode) {
    throw new Error('Missing tablist component for tabbed ui.')
  }
  return new TabList(tabListNode)
}

const createTabPanels = (node: HTMLElement): TabPanel[] => {
  const panelNodes = node.querySelectorAll<HTMLElement>(Selectors.tabPanel)
  return Array.from(panelNodes).map(node => new TabPanel(node))
}

export class TabsComponent {
  private tabList: TabList
  private panels: TabPanel[]

  constructor(
    private node: HTMLElement
  ) {
    this.tabList = createTabList(node)
    this.panels = createTabPanels(node)
    this.tabList.on('tabActivated', this.handleTabActivated)
    window.addEventListener('hashchange', this.handleLocationHashChanged)
  }

  async activateInitialPanel() {
    const activated = await this.activateLocationTarget()
    if (!activated) {
      await this.tabList.activateTabAt(0)
    }
  }

  private get activePanel() {
    return this.panels.find(panel => panel.isActive)
  }

  private async activatePanel(panelId: string) {
    const panel = this.panels.find(panel => panel.id === panelId)
    if (!panel) {
      return
    }
    const activePanel = this.activePanel
    if (activePanel === panel) return
    if (activePanel) await activePanel.deactivate()
    await panel.activate()
  }

  private async activateLocationTarget() {
    const hash = getLocationHash()
    if (this.panels.find(panel => panel.id === hash)) {
      await this.tabList.activateTabById(hash)
      return true
    }
    const hashTarget = document.getElementById(hash)
    if (hashTarget && this.node.contains(hashTarget)) {
      const panel = this.panels.find(panel => panel.contains(hashTarget))
      if (panel) {
        await this.tabList.activateTabById(panel.id)
        return true
      }
    }
    return false
  }

  private handleTabActivated = async (event: TabActivatedEvent) => {
    const {activeTab} = event.detail
    const panelId = activeTab.controls
    if (!panelId) {
      throw new Error('Missing aria-controls attribute for tab.')
    }
    await this.activatePanel(panelId)
  }

  private handleLocationHashChanged = async () => {
    await this.activateLocationTarget()
  }
}
