export const Classes = {
  tabList: 'o-tabList',
  tab: 'o-tabList_tab',
  activeTab: '-isActive',
  activeTabIndicator: 'o-tabList_tabIndicator',
  tabPanel: 'o-tabs_panel',
  activePanel: '-isActive',
}

export const Selectors = {
  tabsComponent: '.o-tabs',
  tabList: `.${Classes.tabList}`,
  activeTab: `.${Classes.activeTab}`,
  activeTabIndicator: `.${Classes.activeTabIndicator}`,
  tabPanel: `.${Classes.tabPanel}`,
  activePanel: `.${Classes.activePanel}`,
}
