declare const gtag: Gtag.Gtag

export type ConsentType = 'all' | 'essential'

export const updateConsent = (type: ConsentType) => {
  switch (type) {
    case 'all':
      gtag('consent', 'update', {
        analytics_storage: 'granted',
        ad_storage: 'granted',
      })
      break
    case 'essential':
      gtag('consent', 'update', {
        analytics_storage: 'denied',
        ad_storage: 'denied',
      })
      break
  }
}
